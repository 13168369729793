import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import BackgroundSection from "../components/BackgorundTest"
import style from "./styles.module.css"
import { dogNames } from "../data/constans"

const HeaderMain = () => {
  return (
    <ul className={style.list}>
      <li className={style.itemList} style={{ float: "left" }}>
        <Link to="/" className={style.itemListContent}>
          [Logo del sitio]
        </Link>
      </li>
      <li className={style.itemList}>
        <Link className={style.itemListContent} to="/">
          Home
        </Link>
      </li>
      <li className={style.itemList}>
        <Link className={style.itemListContent} to="/">
          Contact
        </Link>
      </li>
      <li className={style.itemList}>
        <Link className={style.itemListContent} to="/">
          About
        </Link>
      </li>
    </ul>
  )
}

const NameList = () => {
  return (
    <ul style={{columnCount: 2, width:"50%"}}>
      {dogNames.map(n => (
        <li>{n}</li>
      ))}
    </ul>
  )
}

const Home = () => (
  <>
    <SEO title="Home" />
    <BackgroundSection>
      {/*<HeaderMain />*/}
      {/*<Link to="/page-2/">Go to page 2</Link>*/}
      <p>Elige el nombre para tu perro</p>
      <NameList />
    </BackgroundSection>
  </>
)

export default Home
