export const dogNames = [
    "Newman","Newton","Nicky","Nico","Nicolás","Nilo","Obelix","Onion","Oriundo","Orgulloso","Obby","Oppy","Pocho","Pocholo",
    "Poker","Pol","Poly",
    "Polo","Pompón","Poncho","Pongo","Pool","Porky","Príncipe","Puchi","Pufy","Puka","Pulgarcito","Punk","Pupi","Puppys",
    "Pushkin","Puskas","Quechu","Quivira","Quevedo","Quin","Rabito","Raico","Raiser","Rally","Rambo","Ramón","Ramsés","Randy","Ranger","Rasta","Rasty",
    "Raúl",
    "Ray",
    "Rayo",
    "Remberto",
    "Rex",
    "Rey",
    "Richard",
    "Richie",
    "Rick",
    "Ricky",
    "Ringo",
    "Rinti",
    "Rintintin",
    "Risco",
    "Radu",
    "Ray",
    "Rhino",
    "Rex",
    "Rocco",
    "Sreck",
    "Sirius",
    "Seimour",
    "Siniestro",
    "Sing-sing",
    "Suggar",
    "Seilor",
    "Taisson",
    "Thor",
    "Taj",
    "Tajibo",
    "Talco",
    "Tambor",
    "Tango",
    "Tano",
    "Tanque",
    "Tao",
    "Tarzán",
    "Tass",
    "Tato",
    "Tatoo",
    "Tauro",
    "Teddy",
    "Teo",
    "Tequila",
    "Texmex",
    "Thai",
    "Thomas",
    "Thor",
    "Tibo",
    "Tigre",
    "Tim",
    "Timbál",
    "Timmy",
    "Tino",
    "Tintán",
    "Tintín",
    "Titán",
    "Tito",
    "Titus",
    "Tro",
    "Tyrion",
    "Tyrrell",
    "Tzar",
    "Turko",
    "Titán",
    "Toro",
    "Urco",
    "Udols",
    "Uzbek",
    "Uaji",
    "Vincensso",
    "Vito",
    "Valdo",
    "Van Damme",
    "Verdi",
    "Vico",
    "Vigo",
    "Volton",
    "Yak",
    "Yeti",
    "Yurgen"
] 

